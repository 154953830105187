input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea ,textarea.materialize-textarea{
	background-color:#fff;
	border:1px solid #333;
	text-indent:20px;
	margin:10px 0;
	&:focus:not([readonly]) {
		border-bottom: 1px solid #333;
		box-shadow: 0 0 0 0 #333;
	}
}
[type="checkbox"] + label{
	padding-left:24px;
	margin-right:10px;
}
.file-path-wrapper{
	input{
		background-color:transparent;
	}
}
p{
	margin:10px 0;
}
a{
	position:relative;
	&:after{
		content:'';
		position:absolute;
		bottom:-5px;
		width:0%;
		left:50%;
		height:3px;
		background-color:#3189DD;
		@include transition(300ms all ease-out);
	}
	&.pink-text:after{
		background-color:#e91e63;
	}
	&:hover{
		&:after{
			width:100%;
			left:0%;
		}
	}
	&.btn,&.brand-logos{
		&:after{display:none;}
	}
}
.header-title{
	margin:0;
}
.btn{
	text-transform:none;
	font-weight:400;
	color:#fff;
}
.button-container{
	@extend .par-large;
	.btn{
		margin-right:15px;
	}
}
.main-container,.region-fare-container{
	a{
		color:#000;
		&:after{
			background-color:#000;
			left:0%;
			height:2px;
			@include transition(500ms all ease-out);
		}
	}
	a.btn{color:#fff;}
}
#main-wrapper{
	.white-container{
		background-color:#fff;
		.container{
			margin:0 auto 20px auto;
		}
	}
}
.uploaded-image{
	max-width:400px;
	&.active{
		margin:0 0 15px 0;
	}
}