.login-container.forgotten-password-container{
	width:1024px;
	label{color:#000;}
	label,input{
		line-height:49px;
		height:49px;
		@extend .par-large;
	}
	.forgotten-password-input-container {
		margin:25px 0;
	}
	#forget-email{
		background-color:#eee;
		border:none; 
		text-indent:20px;
		&:focus{
			background-color:#ddd;
		}
	}
	&.second{
		width:800px;
		.z-depth-1.grey.lighten-4{
			padding:10px 15px 50px;
		}
	}
}