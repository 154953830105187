// ionclude all your related style here in the header, you can leave it empty if the page has no header
html, body { height:100%; min-height:100%; }
header {}
.nav-wrapper{
	height:70px;
}
nav{
	position:fixed;
	background-color:#fff;
	padding:0px 15px 0 15px;
	// box-shadow:0 0px 1px 0 rgba(0, 0, 0, 0.14);
	box-shadow:none;
	border-bottom:1px solid #ddd;
	top:0;
	z-index:9;
	a{
		color:#2647A4;
	}
	.logout {
		background-color:#fff;
		color:#000;
		&:hover{
			color:#fff;
			background-color:#244298;
		}
	}
	.home-tabs{
		float:left;
		height:70px;
		margin-left:50px;
		.tab{
			background-color:#fff;
			line-height:70px;
			height:70px;
			a{
				color:#2647A4;
				text-transform:initial;
				@include transition(300ms all ease-out);
			}
		 	a.active{
	 			position:relative;
				color:#00b3f0;
				&:after{
					content:'';
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:4px;
					background-color:#4ED0F6;
				}
		 	}
		 	a.active,a:hover{
		 		border-top:none;
		 	}
			a:hover{
				background-color:#4ED0F6;
				color:#fff;
			}
		}
		.indicator{
			background-color:#00b3f0;;
			top:0;
			height:4px;
		}
	}
}
.second-nav{
	a{
		margin-left:10px;
		&:last-child{
			margin-left:0px;
		}
		&.btn{
			padding-left:15px;
			padding-right:15px;
		}
	}
  .title {
    margin: 0;
    line-height: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
    font-weight: bold;
  }
}
.tabs{
	height:auto;
	.tab{
		background-color:#fff;
		line-height:30px;
		height:50px;
		a{
			padding:10px 15px;
			margin:0;
			background:#244298;
			color:#fff;
			text-transform:initial;
			@extend .par-medium;
			@include transition(300ms all ease-out);

			&.global-component {
				background: #5ba500;
			}
		}
	 	a.active{
			color:#244298;
	 	}
	 	a.active,a:hover{
	 		background-color:#fff;
			&.global-component {
				color: #000;
			}
	 	}
		a:hover{
			background-color:#fff;
			color:#244298;
			&.global-component {
				color: #000;
			}
		}
	}
	.indicator{
		background-color:#244298;
		bottom:4px;
		height:4px;
		z-index: 2;
	}
}
