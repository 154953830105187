.banner-add-container{
	.add-new-table .bg-img-container .file-path-wrapper input.file-path{
		background-color:transparent;
	}
	h4{
		padding:20px 30px;
		color:#000;
		background-color:#fff;
		font-weight:bold;
		@extend .custom-header4;
	}
	table th, table td{
		padding: 10px 30px;
	}
}