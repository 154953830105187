// includes all tags for paragraphs and titles such as header, p, span
h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1em; }
h5 { font-size: 0.83em; }
h6 { font-size: 0.67em; }
.par p { padding:10px 0px; }
.par2 p { padding:5px 0px; }
.bp-center { text-align:center; }
.bp-left { text-align:left; }
.bp-right { text-align:right; }
.bp-justify { text-align:justify; }
.bp-tt { text-transform:uppercase; }
// .par-small { font-size: $fontSmall; }
.par-small { @include responsive-font-size (8px, 10px, 640px, 1200px, 0px); }
.par-medium { @include responsive-font-size (12px, 14px, 640px, 1200px, 0px); }
.par-large { @include responsive-font-size (13px, 16px, 640px, 1200px, 0px); }
.par-xlarge { @include responsive-font-size (14px, 18px, 640px, 1200px, 0px); }
.par-xxlarge { @include responsive-font-size (16px, 20px, 640px, 1200px, 0px); }
.par-max { @include responsive-font-size (24px, 28px, 640px, 1200px, 0px); }
.custom-header4 { @include responsive-font-size (24px, 28px, 640px, 1200px, 0px); }
