.main-container{
	table.table-approval-status{
		a{
			position:relative;
			color:#3189DD;
			&:after{
				content:'';
				position:absolute;
				bottom:-5px;
				width:0%;
				left:50%;
				height:3px;
				background-color:#3189DD;
				@include transition(300ms all ease-out);
			}
			&:hover{
				&:after{
					width:100%;
					left:0%;
				}
			}
			&.btn,&.brand-logos{
				&:after{display:none;}
			}
			&.btn{
				color:#fff;
			}
      &.cr-inherit{
        color:inherit;
      }
		}
	}
}
.ind-approval-container{
	@extend .par-large;
	.button-container{
		margin:25px 0 35px;
		a{
			@extend .par-medium;
			&.btn{
				padding-left:15px;
				height:auto;
				text-align:center;
				padding-right:15px;
			}
		}
	}
}
