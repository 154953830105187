body{
	background-color:#E4E7F0;
}
#main-wrapper{
	.container{
		background-color:#fff;
		margin:20px auto;
	}
}
.content-management{
	.container{
		min-width:1280px;

	}
	.main-container{
		background-color:#fff;
		margin:20px auto;
		padding:1px 0;
	}
	.brand-logos{
		position:relative;
		float:left;
		max-width:150px;
		line-height:2em;
		margin-top:14px;
	}
	h4{
		color:#fff;
		padding:10px 15px;
		margin:0;
		@extend .par-xxlarge;
		background:#244298;
	}
	table{
		tr:nth-child(odd){
			background:#eee;
		}
		tr:nth-child(even){
			background:#fff;
		}
		th, td{
			padding:10px 15px;
		}
		.type{
			min-width:123px;
		}
		.sending-date{
			min-width:103px;
		}
		.regions{
			min-width:100px;
		}
		.action-btn-container{
			min-width:330px;
		}
		.action-btn{
			// float:left;
			margin-right:5px;
			padding:0px 1rem;
			line-height:2.5em;
			height:2.5em;
			border-radius:5px;
		}
	}
	.fixed-action-btn{
		ul{
			li{
				span{margin-right:15px;}
				min-width:400px;
				margin-left:-400px;
				text-align:right;
			}
		}
	}
}

// for Datepicker
.picker__table {
  tr {
    background-color: transparent !important;
  }
  th, td {
    padding: 0 !important;
  }
}

.clear{
	clear:both;
}
.z-depth-1{
	box-shadow:0 0px 1px 0 rgba(0, 0, 0, 0.14);
}
