.fare-list-container{
	
}
.general-container{
	padding:5px 15px;
}
table.fare-list-table{
	tr{
		th:nth-child(1){width:30%;}
		th:nth-child(2){width:25%;}
		th:nth-child(3){width:25%;}
		th:nth-child(4){width:20%;}

		th:nth-child(1),th:nth-child(2),th:nth-child(3){
			text-align:center;				
		}
		td:nth-child(2),td:nth-child(3){
			text-align:center;				
		}
	}
}
.status{
	
}
.layout{
	position:relative;
	color:#000;
	.overlay{
		position:absolute;
		width:100%;
		height:100%;
		background:#fff;
		opacity:0.50;
		z-index:4;
		@include transition(all 300ms ease-out);
	}
	.overlay:hover{
		opacity:0.7;
	}
	.header-title{
		padding:10px 15px;
		color:#000;
		background-color:#b3e5fc;
		font-weight:900;
	}
	table{
		@extend .par-medium;
		th{background-color:transparent}
		width:700px;
	}
	input{
		padding:0;
		margin:0;
		text-indent:0;
		background:transparent;
		border:none !important;
		height:auto;
		@extend .par-medium;
		
		&.bold{
			font-weight:900;
			@extend .par-large;
		}
		&.red{
			color:red;
		}
	}
	input:focus,input:active{
		border:none;
		outline:none;
	}
}
.section{
	position:relative;
}
.layout2,.layout3{
	table{
		tr:first-child,tr:nth-child(2){
			background-color:#fff;
		}
		border:1px solid #eee;
	}
}
.layout2{
	input{
		text-align:center;
	}
}
.layout3{
	table{
		tr{
			td:first-child{
				width:400px
			}
			.promotion-list{
				max-height:250px;
				overflow-y:auto;
			}
		}
	}
	input.blue-text{
		float:left;
		width:60%;
		color:blue;
	}
	.row{
		position:relative;
		width:calc( 100% - 30px );
		border-bottom:1px solid #eee;
		margin:15px auto 20px auto;
	}
	.currency{
		position:relative;
		width:40%;
		float:left;
		color:#debb00;
		font-weight:bold;
		input{
			width:calc( 100% - 25px );
			text-align:right;
		}
		label{
			position:absolute;
			@extend .par-small;
			color:#debb00 !important;
		}
		.currency-sign{
			margin-left:-30px;
			margin-top:-10px;
		}
		.from{
			margin-top:-20px;
			right:25px;
		}
		.arrow{
			right:0;
			@extend .par-large;
		}
	}
}
#fare{
	&.add-new-table .selector-list{
		min-width:210px;
	}
	[type="checkbox"]:checked + label:before{
		left:0px;
	}
	.vdn{
		margin:0 10px;
	}
}
.btn-layout{
	position:absolute;
	top:calc( 1rem + 25px );
	margin-left:10px;
}