.login-container{
	position:relative;
	width:400px;
	max-width:100%;
	margin:auto;
	text-align:center;
	.logo-img{
		margin:20px 0;
		img{
			max-height:40px;
		}
	}
	.input-field{
		text-align:left;
	}
	.login-input-container{
		padding: 32px 48px 0px 48px;
		@media (max-width:500px){
		}
	}
	input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea, textarea.materialize-textarea{
		text-indent:0px;
		margin-top:0;
		margin-bottom:20px;
		background:none;
		border-top:none;
		border-right:none;
		border-left:none;
		// border-bottom:solid 1px;
	}
}