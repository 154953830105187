.ind-edm{
	.header-title,.header-status{
		padding:10px 15px;
		margin:0;
		color:#fff;
		@extend .par-large;
		&.header-title{
			background-color:#244298;
		}
	}
	p.header-status{
		float:left;
		background:none;
		color:#000;
		font-weight:bold;
		padding:10px 15px;
		&.mt1{
			margin-top:10px;
		}
	}
	table{
		@extend .par-large;
		p{
			margin:8px 0;
		}
		.thumbnail{
			max-width:200px;
		}
		.btn{
			height:auto;
			text-transform:initial;
		}
		tr:nth-child(odd){
			background-color:#fff;
		}
		tr:nth-child(even){
			background-color:#eee;
		}
		tr:nth-child(1){
			color:#fff;
		}
		tr{
			th{
				background-color:#244298;
				border-radius:0px;
				position:relative;
			}
			th:first-child{
				width:9%;
			}
			th:nth-child(2),th:nth-child(3),th:nth-child(4){
				width:30%;
				position:relative;
				&:after{
					content:'';
					position:absolute;
					left:-1px;
					width:1px;
					height:20px;
					background-color:#fff;
					top:11px;
				}
			}
		}
	}
}
.edm-individual.ind-edm{
	.btn{
		font-weight:400;
	}
	table tr th{
		background:none;
	}
	table.add-new-table{
		tr{
			td:first-child{
				width:378px;
			}
		}
	}
	tr{
		&.pending,&.pass{td{padding:15px;}}
	}
	tr.pending{
		background-color:#fde1bc;
		.header-status{
			border:5px solid #ff8f00;
		}
	}
	tr.pass{
		background-color:#ccfbc3;
		.header-status{
			border:5px solid #61c165;
		}
	}
}
.email-title{
	margin:0;
	line-height:56px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 600px;
	font-weight:bold;
	color:#e91e63;
	@extend .par-medium;
}
.email-seding-date input {
  text-indent: 0;
  width: 100px;
  font-size: 12px;
  border-bottom:0;
  border:0;
  background: transparent;
}
.button_edit_sending {
  top: 20px;
  left: 0;
  position: absolute;
  color: blue;
}
.file-field .btn{
	height:2.5rem;
	line-height:2.5rem;
}
.file-path-wrapper input[type=text]{
	height:2.5rem;
	line-height:2.5rem;
}
.btn-outer-container{
	padding:10px 15px;
	.btn{
		margin:0 5px;
	}
	.btn:first-child{
		margin-left:0;
	}
}
