// groups of multiplied css like CSS3 for cross browsing

// for css3 transition
@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
      -ms-transition: $args;
       -o-transition: $args;
          transition: $args;
}
// example use
//.class-name { @include transition(all 0.5s ease); }

// for css3 transition delay
@mixin transition-delay($args...) {
  -webkit-transition-delay: $args;
     -moz-transition-delay: $args;
      -ms-transition-delay: $args;
       -o-transition-delay: $args;
          transition-delay: $args;
}
// example use
//.class-name { @include transition(all 0.5s ease); }



// for css3 transform
@mixin transform($transform...) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}


// for css3 transform
@mixin transform-origin($transform-origin...) {
  -webkit-transform-origin: $transform-origin;
     -moz-transform-origin: $transform-origin;
      -ms-transform-origin: $transform-origin;
       -o-transform-origin: $transform-origin;
          transform-origin: $transform-origin;
}
// example use
//.class-name { @include transition(all 0.5s ease); }



// for css3 transform style
@mixin transform-style($transform-style...) {
  -webkit-transform: $transform-style;
     -moz-transform: $transform-style;
      -ms-transform: $transform-style;
       -o-transform: $transform-style;
          transform: $transform-style;
}
// example use
//.class-name { @include transform-style(preserve-3d); }



// for css3 animation
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} { @content; }
     @-moz-keyframes #{$animation-name} { @content; }
      @-ms-keyframes #{$animation-name} { @content; }
       @-o-keyframes #{$animation-name} { @content; }
          @keyframes #{$animation-name} { @content; }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
     -moz-animation: #{$str};
      -ms-animation: #{$str};
       -o-animation: #{$str};
          animation: #{$str};
}
// for example use
// @include keyframes(animationName) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }
// .class-name {
//   @include animation('animationName 5s 3');
// }



@mixin animation-delay($animdelay) {
  -webkit-animation-delay: #{$animdelay};
     -moz-animation-delay: #{$animdelay};
      -ms-animation-delay: #{$animdelay};
       -o-animation-delay: #{$animdelay};
          animation-delay: #{$animdelay};
}


@mixin animation-fill-mode($fillmode) {
  -webkit-animation-fill-mode: #{$fillmode};
     -moz-animation-fill-mode: #{$fillmode};
      -ms-animation-fill-mode: #{$fillmode};
       -o-animation-fill-mode: #{$fillmode};
          animation-fill-mode: #{$fillmode};
}
// @include animation-fill-mode(forwards);


// for border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
// example use
//.class-name { @include border-radius(10px); }



// for input appearance
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
          appearance: $appearance;
}
// example use
//.class-name { @include appearance(none); }



// for user select
@mixin user-select($userselect) {
  -webkit-user-select: $userselect;
     -moz-user-select: $userselect;
      -ms-user-select: $userselect;
          user-select: $userselect;
}
// example use
//.class-name { @include user-select(none); }



// for pointer events
@mixin pointer-events($pointerevents) {
  -webkit-pointer-events: $pointerevents;
     -moz-pointer-events: $pointerevents;
      -ms-pointer-events: $pointerevents;
          pointer-events: $pointerevents;
}
// example use
//.class-name { @include pointer-events(none); }



// for box sizing
@mixin box-sizing($boxsizing) {
  -webkit-box-sizing: $boxsizing;
     -moz-box-sizing: $boxsizing;
      -ms-box-sizing: $boxsizing;
          box-sizing: $boxsizing;
}
// example use
//.class-name { @include pointer-events(none); }



// for placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} { @content; }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') { @content; }
  @include optional-at-root(':-moz-placeholder') { @content; }
  @include optional-at-root('::-moz-placeholder') { @content; }
  @include optional-at-root(':-ms-input-placeholder') { @content; }
}



// for opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
// example use
//.class-name { @include opacity(0.8); }



// for backface visibility
@mixin backface-visibility($backface) {
  -webkit-backface-visibility: $backface;
     -moz-backface-visibility: $backface;
      -ms-backface-visibility: $backface;
          backface-visibility: $backface;
}
// example use
// @include backface-visibility(hidden);



// for background linear gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
// example use
//@include linear-gradient(hotpink, tomato);


// for responsive font
@mixin responsive-font-size($min-font-size, $max-font-size, $min-screen-width, $max-screen-width, $font-size-step: 0.1em) {
    font-size: $max-font-size;
    @media all and (max-width: $min-screen-width) {
        font-size: $min-font-size;
    }
    @if $font-size-step <= 0 {

    }
    @else {
        $min-scale-factor: $min-font-size / $min-screen-width / 1em * 1px;
        $max-scale-factor: $max-font-size / $max-screen-width / 1em * 1px;
        $screen-width: round($max-screen-width);
        $font-size: $max-font-size;
        @while $screen-width >= $min-screen-width {
            $progress: ($screen-width - $min-screen-width) / ($max-screen-width - $min-screen-width);
            $scale-factor: $min-scale-factor + $progress * ($max-scale-factor - $min-scale-factor);
            $font-size: $screen-width * $scale-factor / 1px * 1em;
            @media all and (max-width: $screen-width) {
                font-size: $font-size;
            }
            $screen-width-step: $font-size-step / $scale-factor / 1em * 1px;
            @if $screen-width-step < 1px {
                $screen-width-step: 1px;
            }
            $screen-width: round($screen-width - $screen-width-step);
        }
    }
}
// example use
// .class-name { @include responsive-font-size (12px, 14px, 640px, 1200px, 0px); }

// for responsive or so called media queries or breakpoints
// you can adjust it anytime depending on the max width of the content
@mixin breakpoint($point) {
  @if $point == maxwidth {
    @media (max-width: $maxWidth) { @content; }
  }
  @else if $point == desktop {
    @media (max-width: 1024px) { @content; }
  }
  @else if $point == non-laptop {
    @media (min-width: 951px) { @content; }
  }
  @else if $point == laptop {
    @media (max-width: 950px) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: 810px) { @content; }
  }
  @else if $point == tablet2 {
    @media (max-width: 760px) { @content; }
  }
  @else if $point == phablet {
    @media (max-width: 640px)  { @content; }
  }
  @else if $point == mobile {
    @media (max-width: 480px)  { @content; }
  }
  @else if $point == mobile2 {
    @media (max-width: 400px)  { @content; }
  }
  @else if $point == mobile3 {
    @media (max-width: 360px)  { @content; }
  }
  @else if $point == mobile4 {
    @media (max-width: 320px)  { @content; }
  }
}
// example use
// @include breakpoint(tablet) {
//  / put your css here /
// }
