.ind-fare{
	[type="radio"]:not(:checked) + label, [type="radio"]:checked + label{
		height:auto;
	}
}
.region-fare-container .modal,
#fare{
	min-width:1024px;
}
.destination-title{
  margin-top: 35px;
	.btn{
		font-size:12px;
		padding-left:16px;
		padding-right:16px;
		color:#fff;
		border-radius:5px;
	}
	.btn:first-child{
		margin-left:15px;
	}
}
[type="checkbox"] + label.group-from-checkbox{
	padding-left:30px;
	height:17px;
}
input[type=text].group-from-checkbox-input{
	width:250px;
}
.region-fare-container .general-container{
	[type="radio"]:not(:checked) + label, [type="radio"]:checked + label{height:auto;}
	.layout{
		margin-top:-11px;
		.overlay{
			opacity:0;
			&:hover{opacity:0;}
		}
	}
	input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea, textarea.materialize-textarea{
		background:transparent;
	}
}
.destination{
	[type="radio"] + label:before, [type="radio"] + label:after{
		margin:4px 0;
	}
}
input[type=text].fares-vdn{
	width:300px;
	margin-left:15px;
	margin-right:15px;
}

.fare-date{
	line-height:56px;
	margin:0;
	font-weight:bold;
	color:#e91e63;
	@extend .par-medium;
}
