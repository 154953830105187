.region-container{
	margin-top:10px;
	::-webkit-scrollbar { width:2px; background:#fff;height:2px; }
	/* Track */
	::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  }
	/* Handle */
	::-webkit-scrollbar-thumb { background: #244298; }
	.region-tabs{
		.indicator{display:none;}
	}
	a{
		color:#000;
	}
}
