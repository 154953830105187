/** font face **/
/** font face **/
/* Addresses margins handled incorrectly in IE 6/7. */
* {
  margin: 0;
  padding: 0;
  outline: none;
}

html, body {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 12px;
  line-height: 1.4;
  color: #000000;
}

/*
 * Addresses `font-family` inconsistency between `textarea` and other form
 * elements.
*/
html, button, input, select, textarea {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/***** [Reserve CSS Stylesheet] *****/
.opac a:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.invisible {
  visibility: hidden;
}

/* Hide visually and from screenreaders, but maintain layout */
.hidden {
  display: none !important;
  visibility: hidden;
}

/* Hide from both screenreaders and browsers: h5bp.com/u */
small {
  font-size: 80%;
}

/* Addresses inconsistent and variable font size in all browsers. */
/***** CSS3 SCROLL BAR Styles *****/
::-webkit-scrollbar {
  width: 7px;
  background: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Corrects `block` display not defined in IE 6/7/8/9 and Firefox 3. */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

/* Corrects `inline-block` display not defined in IE 6/7/8/9 and Firefox 3. */
audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/*
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
*/
audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * Addresses styling for `hidden` attribute not present in IE 7/8/9, Firefox 3,
 * and Safari 4.
 * Known issue: no IE 6 support.
*/
[hidden] {
  display: none;
}

/* ==========================================================================
   Links
   ========================================================================== */
/*
 * Addresses `outline` inconsistency between Chrome and other browsers.
*/
a {
  outline: none;
  color: #3189DD;
  text-decoration: none;
  _noFocusLine: expression(this.hideFocus=true);
  cursor: pointer;
}

a:active, a:hover, a:focus {
  outline: none;
  text-decoration: none;
  _noFocusLine: expression(this.hideFocus=true);
}

/* ==========================================================================
   Typography
   ========================================================================== */
/* Addresses styling not present in IE 7/8/9, Safari 5, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted;
}

/* Addresses style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome. */
b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

/* Addresses styling not present in Safari 5 and Chrome. */
dfn {
  font-style: italic;
}

/* Addresses styling not present in IE 6/7/8/9. */
mark {
  background: #ff0;
  color: #000;
}

/* Corrects font family set oddly in IE 6, Safari 4/5, and Chrome. */
code, kbd, pre, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/* Improves readability of pre-formatted text in all browsers. */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Addresses CSS quotes not supported in IE 6/7. */
q {
  quotes: none;
}

/* Addresses `quotes` property not supported in Safari 4. */
q:before, q:after {
  content: '';
  content: none;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
*/
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
   Lists
   ========================================================================== */
/*
 * Addresses paddings set differently in IE 6/7.
*/
/*
 * Corrects list images handled incorrectly in IE 7.
 */
nav ul, nav ol, li {
  list-style: none;
  list-style-image: none;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */
/*
 * 1. Removes border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/*
 * Corrects overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
   Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */
/*
 * Corrects margin displayed oddly in IE 6/7.
*/
form {
  margin: 0;
}

/* Define consistent border, margin, and padding. */
fieldset {
  border: 1px solid #c0c0c0;
}

/*
 * 1. Corrects color not being inherited in IE 6/7/8/9.
 * 2. Corrects text not wrapping in Firefox 3.
 * 3. Corrects alignment displayed oddly in IE 6/7.
*/
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/*
 * 1. Corrects font size not being inherited in all browsers.
 * 2. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improves appearance and consistency in all browsers.
 */
button, input, select, textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
  outline: none;
  _noFocusLine: expression(this.hideFocus=true);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

textarea {
  outline: none;
  resize: none;
}

/*
 * Addresses Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
*/
button, input {
  line-height: normal;
}

/*
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Corrects inability to style clickable `input` types in iOS.
 * 3. Improves usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/*
 * Re-set default cursor for disabled elements.
*/
button[disabled], input[disabled] {
  cursor: default;
}

/*
 * 1. Addresses box sizing set to content-box in IE 8/9.
 * 2. Removes excess padding in IE 8/9.
 * 3. Removes excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
*/
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/*
 * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}

/*
 * Removes inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
*/
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Removes inner padding and border in Firefox 3+. */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * 1. Removes default vertical scrollbar in IE 6/7/8/9.
 * 2. Improves readability and alignment in all browsers.
*/
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* ==========================================================================
   Tables
   ========================================================================== */
/* Remove most spacing between table cells. */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/* * A better looking default horizontal rule */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* Remove the gap between images and the bottom of their containers: h5bp.com/i/440 */
img {
  vertical-align: middle;
}

/* Remove default fieldset styles. */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links for images, or javascript/internal links
    */
  .ir a:after,
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}

.par p {
  padding: 10px 0px;
}

.par2 p {
  padding: 5px 0px;
}

.bp-center {
  text-align: center;
}

.bp-left {
  text-align: left;
}

.bp-right {
  text-align: right;
}

.bp-justify {
  text-align: justify;
}

.bp-tt {
  text-transform: uppercase;
}

.par-small, .layout3 .currency label {
  font-size: 10px;
}

@media all and (max-width: 640px) {
  .par-small, .layout3 .currency label {
    font-size: 8px;
  }
}

.par-medium, .tabs .tab a, .layout table, .layout input, .fare-date, .ind-approval-container .button-container a, .email-title {
  font-size: 14px;
}

@media all and (max-width: 640px) {
  .par-medium, .tabs .tab a, .layout table, .layout input, .fare-date, .ind-approval-container .button-container a, .email-title {
    font-size: 12px;
  }
}

.par-large, .button-container, .login-container.forgotten-password-container label, .login-container.forgotten-password-container input, .add-new-table, .popup, .layout input.bold, .layout3 .currency .arrow, .ind-approval-container, .ind-edm .header-title, .ind-edm .header-status, .ind-edm table {
  font-size: 16px;
}

@media all and (max-width: 640px) {
  .par-large, .button-container, .login-container.forgotten-password-container label, .login-container.forgotten-password-container input, .add-new-table, .popup, .layout input.bold, .layout3 .currency .arrow, .ind-approval-container, .ind-edm .header-title, .ind-edm .header-status, .ind-edm table {
    font-size: 13px;
  }
}

.par-xlarge {
  font-size: 18px;
}

@media all and (max-width: 640px) {
  .par-xlarge {
    font-size: 14px;
  }
}

.par-xxlarge, .content-management h4 {
  font-size: 20px;
}

@media all and (max-width: 640px) {
  .par-xxlarge, .content-management h4 {
    font-size: 16px;
  }
}

.par-max {
  font-size: 28px;
}

@media all and (max-width: 640px) {
  .par-max {
    font-size: 24px;
  }
}

.custom-header4, .banner-add-container h4 {
  font-size: 28px;
}

@media all and (max-width: 640px) {
  .custom-header4, .banner-add-container h4 {
    font-size: 24px;
  }
}

/** CSS for pace preloader **/
body:before, body:after {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.pace {
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #ffffff;
}

.pace-inactive {
  opacity: 0;
  filter: alpha(opacity=0);
}

.pace .pace-progress {
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.5);
}

/** CSS for pace preloader **/
.ui-sortable-handle td {
  cursor: move;
}

html, body {
  height: 100%;
  min-height: 100%;
}

.nav-wrapper {
  height: 70px;
}

nav {
  position: fixed;
  background-color: #fff;
  padding: 0px 15px 0 15px;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  top: 0;
  z-index: 9;
}

nav a {
  color: #2647A4;
}

nav .logout {
  background-color: #fff;
  color: #000;
}

nav .logout:hover {
  color: #fff;
  background-color: #244298;
}

nav .home-tabs {
  float: left;
  height: 70px;
  margin-left: 50px;
}

nav .home-tabs .tab {
  background-color: #fff;
  line-height: 70px;
  height: 70px;
}

nav .home-tabs .tab a {
  color: #2647A4;
  text-transform: initial;
  -webkit-transition: 300ms all ease-out;
  -moz-transition: 300ms all ease-out;
  -ms-transition: 300ms all ease-out;
  -o-transition: 300ms all ease-out;
  transition: 300ms all ease-out;
}

nav .home-tabs .tab a.active {
  position: relative;
  color: #00b3f0;
}

nav .home-tabs .tab a.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #4ED0F6;
}

nav .home-tabs .tab a.active, nav .home-tabs .tab a:hover {
  border-top: none;
}

nav .home-tabs .tab a:hover {
  background-color: #4ED0F6;
  color: #fff;
}

nav .home-tabs .indicator {
  background-color: #00b3f0;
  top: 0;
  height: 4px;
}

.second-nav a {
  margin-left: 10px;
}

.second-nav a:last-child {
  margin-left: 0px;
}

.second-nav a.btn {
  padding-left: 15px;
  padding-right: 15px;
}

.second-nav .title {
  margin: 0;
  line-height: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  font-weight: bold;
}

.tabs {
  height: auto;
}

.tabs .tab {
  background-color: #fff;
  line-height: 30px;
  height: 50px;
}

.tabs .tab a {
  padding: 10px 15px;
  margin: 0;
  background: #244298;
  color: #fff;
  text-transform: initial;
  -webkit-transition: 300ms all ease-out;
  -moz-transition: 300ms all ease-out;
  -ms-transition: 300ms all ease-out;
  -o-transition: 300ms all ease-out;
  transition: 300ms all ease-out;
}

.tabs .tab a.global-component {
  background: #5ba500;
}

.tabs .tab a.active {
  color: #244298;
}

.tabs .tab a.active, .tabs .tab a:hover {
  background-color: #fff;
}

.tabs .tab a.active.global-component, .tabs .tab a:hover.global-component {
  color: #000;
}

.tabs .tab a:hover {
  background-color: #fff;
  color: #244298;
}

.tabs .tab a:hover.global-component {
  color: #000;
}

.tabs .indicator {
  background-color: #244298;
  bottom: 4px;
  height: 4px;
  z-index: 2;
}

.footer {
  z-index: 2;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  text-align: center;
}

#main-container {
  height: 100%;
  height: auto !important;
  min-height: 100%;
}

.hide {
  position: relative;
}

.ease, a, input[type="submit"], button {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.loader {
  position: relative;
  overflow: hidden;
  background: #fff url("../images/page_template/loading.gif") no-repeat center;
}

.auto {
  max-width: 1024px;
  margin: 0 auto;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clr {
  display: block;
  float: none;
  clear: both;
}

.bp-set {
  display: inline-block;
  *display: inline;
}

.bp-set.vm {
  vertical-align: middle;
}

.bp-set.vt {
  vertical-align: top;
}

.bp-set.vb {
  vertical-align: bottom;
}

.bp-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.bp-rel {
  position: relative;
}

.bp-ab {
  position: absolute;
}

.co-black {
  color: #000000;
}

.co-white {
  color: #ffffff;
}

.pad-edge {
  padding-left: 15px;
  padding-right: 15px;
}

.bp-img img {
  max-width: 100%;
  height: auto;
}

.bp-img.wide img {
  width: 100%;
}

.bp-curve {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.bp-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.mobile-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.bp-centralize {
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.pt1 {
  padding-top: 10px;
}

.pt2 {
  padding-top: 20px;
}

@media (max-width: 640px) {
  .pt2 {
    padding-top: 10px;
  }
}

.pt3 {
  padding-top: 30px;
}

@media (max-width: 640px) {
  .pt3 {
    padding-top: 20px;
  }
}

.pt4 {
  padding-top: 40px;
}

@media (max-width: 640px) {
  .pt4 {
    padding-top: 30px;
  }
}

.pt5 {
  padding-top: 50px;
}

@media (max-width: 640px) {
  .pt5 {
    padding-top: 40px;
  }
}

.pb1 {
  padding-bottom: 10px;
}

.pb2 {
  padding-bottom: 20px;
}

@media (max-width: 640px) {
  .pb2 {
    padding-bottom: 10px;
  }
}

.pb3 {
  padding-bottom: 30px;
}

@media (max-width: 640px) {
  .pb3 {
    padding-bottom: 20px;
  }
}

.pb4 {
  padding-bottom: 40px;
}

@media (max-width: 640px) {
  .pb4 {
    padding-bottom: 30px;
  }
}

.pb5 {
  padding-bottom: 50px;
}

@media (max-width: 640px) {
  .pb5 {
    padding-bottom: 40px;
  }
}

.mt1 {
  margin-top: 10px;
}

.mt2 {
  margin-top: 20px;
}

@media (max-width: 640px) {
  .mt2 {
    margin-top: 10px;
  }
}

.mt3 {
  margin-top: 30px;
}

@media (max-width: 640px) {
  .mt3 {
    margin-top: 20px;
  }
}

.mt4 {
  margin-top: 40px;
}

@media (max-width: 640px) {
  .mt4 {
    margin-top: 30px;
  }
}

.mt5 {
  margin-top: 50px;
}

@media (max-width: 640px) {
  .mt5 {
    margin-top: 40px;
  }
}

.mb1 {
  margin-bottom: 10px;
}

.mb2 {
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .mb2 {
    margin-bottom: 10px;
  }
}

.mb3 {
  margin-bottom: 30px;
}

@media (max-width: 640px) {
  .mb3 {
    margin-bottom: 20px;
  }
}

.mb4 {
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .mb4 {
    margin-bottom: 30px;
  }
}

.mb5 {
  margin-bottom: 50px;
}

@media (max-width: 640px) {
  .mb5 {
    margin-bottom: 40px;
  }
}

.m10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mt10 {
  margin-top: 10px;
}

.ml1 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml2 {
  margin-left: 20px;
}

@media (max-width: 640px) {
  .ml2 {
    margin-left: 10px;
  }
}

.ml3 {
  margin-left: 30px;
}

@media (max-width: 640px) {
  .ml3 {
    margin-left: 20px;
  }
}

.ml4 {
  margin-left: 40px;
}

@media (max-width: 640px) {
  .ml4 {
    margin-left: 30px;
  }
}

.ml5 {
  margin-left: 50px;
}

@media (max-width: 640px) {
  .ml5 {
    margin-left: 40px;
  }
}

.mr1 {
  margin-right: 10px;
}

.mr2 {
  margin-right: 20px;
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.spinner {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  -webkit-animation: rotatee 2s infinite linear;
  -moz-animation: rotatee 2s infinite linear;
  -ms-animation: rotatee 2s infinite linear;
  -o-animation: rotatee 2s infinite linear;
  animation: rotatee 2s infinite linear;
}

.s-dot {
  display: inline-block;
  position: absolute;
  width: 60%;
  height: 60%;
  top: 0;
  background-color: #000000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: bouncee 2s infinite ease-in-out;
  -moz-animation: bouncee 2s infinite ease-in-out;
  -ms-animation: bouncee 2s infinite ease-in-out;
  -o-animation: bouncee 2s infinite ease-in-out;
  animation: bouncee 2s infinite ease-in-out;
}

.s-dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -ms-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
}

.ta-c {
  text-align: center;
}

.fz-1 {
  font-size: 1em;
}

.cr-black {
  color: #000000;
}

@-webkit-keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.explorer .preloader {
  background: #fff url("../images/page_template/loading.gif") no-repeat center;
}

.explorer .preloader .spinner {
  display: none;
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea, textarea.materialize-textarea {
  background-color: #fff;
  border: 1px solid #333;
  text-indent: 20px;
  margin: 10px 0;
}

input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #333;
  box-shadow: 0 0 0 0 #333;
}

[type="checkbox"] + label {
  padding-left: 24px;
  margin-right: 10px;
}

.file-path-wrapper input {
  background-color: transparent;
}

p {
  margin: 10px 0;
}

a {
  position: relative;
}

a:after {
  content: '';
  position: absolute;
  bottom: -5px;
  width: 0%;
  left: 50%;
  height: 3px;
  background-color: #3189DD;
  -webkit-transition: 300ms all ease-out;
  -moz-transition: 300ms all ease-out;
  -ms-transition: 300ms all ease-out;
  -o-transition: 300ms all ease-out;
  transition: 300ms all ease-out;
}

a.pink-text:after {
  background-color: #e91e63;
}

a:hover:after {
  width: 100%;
  left: 0%;
}

a.btn:after, a.brand-logos:after {
  display: none;
}

.header-title {
  margin: 0;
}

.btn {
  text-transform: none;
  font-weight: 400;
  color: #fff;
}

.button-container .btn {
  margin-right: 15px;
}

.main-container a, .region-fare-container a {
  color: #000;
}

.main-container a:after, .region-fare-container a:after {
  background-color: #000;
  left: 0%;
  height: 2px;
  -webkit-transition: 500ms all ease-out;
  -moz-transition: 500ms all ease-out;
  -ms-transition: 500ms all ease-out;
  -o-transition: 500ms all ease-out;
  transition: 500ms all ease-out;
}

.main-container a.btn, .region-fare-container a.btn {
  color: #fff;
}

#main-wrapper .white-container {
  background-color: #fff;
}

#main-wrapper .white-container .container {
  margin: 0 auto 20px auto;
}

.uploaded-image {
  max-width: 400px;
}

.uploaded-image.active {
  margin: 0 0 15px 0;
}

.login-container {
  position: relative;
  width: 400px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.login-container .logo-img {
  margin: 20px 0;
}

.login-container .logo-img img {
  max-height: 40px;
}

.login-container .input-field {
  text-align: left;
}

.login-container .login-input-container {
  padding: 32px 48px 0px 48px;
}

.login-container input:not([type]), .login-container input[type=text], .login-container input[type=password], .login-container input[type=email], .login-container input[type=url], .login-container input[type=time], .login-container input[type=date], .login-container input[type=datetime], .login-container input[type=datetime-local], .login-container input[type=tel], .login-container input[type=number], .login-container input[type=search], .login-container textarea, .login-container textarea.materialize-textarea {
  text-indent: 0px;
  margin-top: 0;
  margin-bottom: 20px;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

.login-container.forgotten-password-container {
  width: 1024px;
}

.login-container.forgotten-password-container label {
  color: #000;
}

.login-container.forgotten-password-container label, .login-container.forgotten-password-container input {
  line-height: 49px;
  height: 49px;
}

.login-container.forgotten-password-container .forgotten-password-input-container {
  margin: 25px 0;
}

.login-container.forgotten-password-container #forget-email {
  background-color: #eee;
  border: none;
  text-indent: 20px;
}

.login-container.forgotten-password-container #forget-email:focus {
  background-color: #ddd;
}

.login-container.forgotten-password-container.second {
  width: 800px;
}

.login-container.forgotten-password-container.second .z-depth-1.grey.lighten-4 {
  padding: 10px 15px 50px;
}

body {
  background-color: #E4E7F0;
}

#main-wrapper .container {
  background-color: #fff;
  margin: 20px auto;
}

.content-management .container {
  min-width: 1280px;
}

.content-management .main-container {
  background-color: #fff;
  margin: 20px auto;
  padding: 1px 0;
}

.content-management .brand-logos {
  position: relative;
  float: left;
  max-width: 150px;
  line-height: 2em;
  margin-top: 14px;
}

.content-management h4 {
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  background: #244298;
}

.content-management table tr:nth-child(odd) {
  background: #eee;
}

.content-management table tr:nth-child(even) {
  background: #fff;
}

.content-management table th, .content-management table td {
  padding: 10px 15px;
}

.content-management table .type {
  min-width: 123px;
}

.content-management table .sending-date {
  min-width: 103px;
}

.content-management table .regions {
  min-width: 100px;
}

.content-management table .action-btn-container {
  min-width: 330px;
}

.content-management table .action-btn {
  margin-right: 5px;
  padding: 0px 1rem;
  line-height: 2.5em;
  height: 2.5em;
  border-radius: 5px;
}

.content-management .fixed-action-btn ul li {
  min-width: 400px;
  margin-left: -400px;
  text-align: right;
}

.content-management .fixed-action-btn ul li span {
  margin-right: 15px;
}

.picker__table tr {
  background-color: transparent !important;
}

.picker__table th, .picker__table td {
  padding: 0 !important;
}

.clear {
  clear: both;
}

.z-depth-1 {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.14);
}

textarea.materialize-textarea {
  text-indent: 0px;
  padding: 10px 20px;
  min-height: 100px;
  max-width: calc( 100% - 50px);
  box-sizing: content-box;
  background: #fff;
}

.add-new-table .bg-img-label {
  padding: 20px 0;
}

.add-new-table label {
  color: #000;
}

.add-new-table input:not([type]), .add-new-table input[type=text], .add-new-table input[type=password], .add-new-table input[type=email], .add-new-table input[type=url], .add-new-table input[type=time], .add-new-table input[type=date], .add-new-table input[type=datetime], .add-new-table input[type=datetime-local], .add-new-table input[type=tel], .add-new-table input[type=number], .add-new-table input[type=search], .add-new-table textarea, .add-new-table textarea.materialize-textarea {
  background-color: #fff;
  border: 1px solid #333;
  text-indent: 20px;
  margin: 10px 0;
}

.add-new-table input:not([type]):focus:not([readonly]), .add-new-table input[type=text]:focus:not([readonly]), .add-new-table input[type=password]:focus:not([readonly]), .add-new-table input[type=email]:focus:not([readonly]), .add-new-table input[type=url]:focus:not([readonly]), .add-new-table input[type=time]:focus:not([readonly]), .add-new-table input[type=date]:focus:not([readonly]), .add-new-table input[type=datetime]:focus:not([readonly]), .add-new-table input[type=datetime-local]:focus:not([readonly]), .add-new-table input[type=tel]:focus:not([readonly]), .add-new-table input[type=number]:focus:not([readonly]), .add-new-table input[type=search]:focus:not([readonly]), .add-new-table textarea:focus:not([readonly]), .add-new-table textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #333;
  box-shadow: 0 0 0 0 #333;
}

.add-new-table textarea.materialize-textarea {
  text-indent: 0px;
  padding: 10px 20px;
  min-height: 100px;
  max-width: calc( 100% - 50px);
  box-sizing: content-box;
}

.add-new-table .datepicker-container {
  max-width: 100%;
  width: 180px;
  position: relative;
}

.add-new-table .datepicker-container:after {
  content: '\f073';
  font-family: "FontAwesome";
  position: absolute;
  right: 10px;
  top: 17px;
  font-size: 2em;
  cursor: pointer;
  z-index: 1;
}

.add-new-table .datepicker-container input.datepicker {
  position: relative;
  z-index: 2;
  background-color: transparent;
  cursor: pointer;
}

.add-new-table [type="radio"]:not(:checked) + label, .add-new-table [type="radio"]:checked + label, .add-new-table [type="radio"] + label {
  padding-left: 25px;
  padding-right: 20px;
  font-size: 1em;
}

.add-new-table table.picker__table tr:nth-child(odd), .add-new-table table.picker__table tr:nth-child(even) {
  background-color: #fff;
}

.add-new-table table.picker__table tr:nth-child(odd) td, .add-new-table table.picker__table tr:nth-child(even) td {
  padding: 0;
}

.add-new-table .bg-img-container {
  margin-top: 0;
}

.add-new-table .bg-img-container input {
  margin: 0;
}

.add-new-table .bg-img-container .file-path {
  border: none;
  text-indent: 0px;
  border-bottom: 1px solid;
}

.add-new-table .dragging, .add-new-table .dragging * {
  cursor: move !important;
}

.add-new-table .dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

.add-new-table .selector-list {
  min-width: 250px;
}

.add-new-table .selector-list p {
  text-align: center;
  font-weight: 700;
  margin: 10px 0;
}

.add-new-table .buttons-container {
  text-align: center;
  margin: 50px;
}

.add-new-table .buttons-container button {
  margin: 5px;
}

.add-new-table .custom-selector-list .ui-selecting {
  background-color: #4ED0F6 !important;
  color: #fff;
}

.add-new-table .custom-selector-list .ui-selected {
  background-color: #4ED0F6 !important;
  color: #fff;
}

.add-new-table .custom-selector-list {
  border: 1px solid #000;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: white;
  min-height: 300px;
}

.add-new-table .custom-selector-list li {
  margin: 3px;
  padding: 10px 15px;
  height: 18px;
  box-sizing: content-box;
  cursor: pointer;
}

.add-new-table .custom-selector-list li:hover {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background-color: #eee;
}

.fixed-table {
  table-layout: fixed;
}

.fixed-table td {
  word-wrap: break-word;
}

.popup.delete, .popup.delete-destination, .popup.clone, .popup.download, .popup.edit-edm {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  padding: 20px 0;
  top: calc( 50% - 180px) !important;
  text-align: center;
}

.popup.delete .modal-footer, .popup.delete-destination .modal-footer, .popup.clone .modal-footer, .popup.download .modal-footer, .popup.edit-edm .modal-footer {
  text-align: center;
}

.popup.delete .modal-footer a, .popup.delete-destination .modal-footer a, .popup.clone .modal-footer a, .popup.download .modal-footer a, .popup.edit-edm .modal-footer a {
  float: none;
}

.popup label {
  color: #000;
}

.popup .header-title {
  background-color: #254583;
  color: #fff;
  padding: 10px 15px;
}

.popup [type="radio"]:not(:checked) + label, .popup [type="radio"]:checked + label {
  padding-left: 30px;
}

.popup .bg-img-container {
  margin-top: 0;
}

.popup .bg-img-container input {
  margin: 0;
}

.popup .bg-img-container .file-path {
  border: none;
  text-indent: 0px;
  border-bottom: 1px solid;
}

.popup .half {
  float: left;
  width: calc( 50% - 30px);
  padding: 0 15px;
}

.popup .half .img-container {
  width: 100%;
  background-color: #ddd;
  min-height: 50px;
}

.popup .half .img-container img {
  max-width: 100%;
}

.popup .half .half {
  width: 50%;
  padding: 0 5px;
}

.popup.modal.banner .modal-footer .btn {
  margin: 8px 5px;
}

.popup.banner table tr {
  padding: 10px 0;
}

.submit-container {
  margin: 20px 15px;
}

.submit-button {
  background-color: #244298 !important;
}

.submit-button.is-sb-green {
  background-color: #4caf50 !important;
}

.content-management .btn {
  font-weight: 900;
  cursor: pointer;
}

.content-management .btn span {
  cursor: pointer;
}

.fares-input {
  width: 40% !important;
  margin: 0 10px !important;
}

.region-container {
  margin-top: 10px;
  /* Track */
  /* Handle */
}

.region-container ::-webkit-scrollbar {
  width: 2px;
  background: #fff;
  height: 2px;
}

.region-container ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.region-container ::-webkit-scrollbar-thumb {
  background: #244298;
}

.region-container .region-tabs .indicator {
  display: none;
}

.region-container a {
  color: #000;
}

.general-container {
  padding: 5px 15px;
}

table.fare-list-table tr th:nth-child(1) {
  width: 30%;
}

table.fare-list-table tr th:nth-child(2) {
  width: 25%;
}

table.fare-list-table tr th:nth-child(3) {
  width: 25%;
}

table.fare-list-table tr th:nth-child(4) {
  width: 20%;
}

table.fare-list-table tr th:nth-child(1), table.fare-list-table tr th:nth-child(2), table.fare-list-table tr th:nth-child(3) {
  text-align: center;
}

table.fare-list-table tr td:nth-child(2), table.fare-list-table tr td:nth-child(3) {
  text-align: center;
}

.layout {
  position: relative;
  color: #000;
}

.layout .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.50;
  z-index: 4;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.layout .overlay:hover {
  opacity: 0.7;
}

.layout .header-title {
  padding: 10px 15px;
  color: #000;
  background-color: #b3e5fc;
  font-weight: 900;
}

.layout table {
  width: 700px;
}

.layout table th {
  background-color: transparent;
}

.layout input {
  padding: 0;
  margin: 0;
  text-indent: 0;
  background: transparent;
  border: none !important;
  height: auto;
}

.layout input.bold {
  font-weight: 900;
}

.layout input.red {
  color: red;
}

.layout input:focus, .layout input:active {
  border: none;
  outline: none;
}

.section {
  position: relative;
}

.layout2 table, .layout3 table {
  border: 1px solid #eee;
}

.layout2 table tr:first-child, .layout2 table tr:nth-child(2), .layout3 table tr:first-child, .layout3 table tr:nth-child(2) {
  background-color: #fff;
}

.layout2 input {
  text-align: center;
}

.layout3 table tr td:first-child {
  width: 400px;
}

.layout3 table tr .promotion-list {
  max-height: 250px;
  overflow-y: auto;
}

.layout3 input.blue-text {
  float: left;
  width: 60%;
  color: blue;
}

.layout3 .row {
  position: relative;
  width: calc( 100% - 30px);
  border-bottom: 1px solid #eee;
  margin: 15px auto 20px auto;
}

.layout3 .currency {
  position: relative;
  width: 40%;
  float: left;
  color: #debb00;
  font-weight: bold;
}

.layout3 .currency input {
  width: calc( 100% - 25px);
  text-align: right;
}

.layout3 .currency label {
  position: absolute;
  color: #debb00 !important;
}

.layout3 .currency .currency-sign {
  margin-left: -30px;
  margin-top: -10px;
}

.layout3 .currency .from {
  margin-top: -20px;
  right: 25px;
}

.layout3 .currency .arrow {
  right: 0;
}

#fare.add-new-table .selector-list {
  min-width: 210px;
}

#fare [type="checkbox"]:checked + label:before {
  left: 0px;
}

#fare .vdn {
  margin: 0 10px;
}

.btn-layout {
  position: absolute;
  top: calc( 1rem + 25px);
  margin-left: 10px;
}

.ind-fare [type="radio"]:not(:checked) + label, .ind-fare [type="radio"]:checked + label {
  height: auto;
}

.region-fare-container .modal,
#fare {
  min-width: 1024px;
}

.destination-title {
  margin-top: 35px;
}

.destination-title .btn {
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  border-radius: 5px;
}

.destination-title .btn:first-child {
  margin-left: 15px;
}

[type="checkbox"] + label.group-from-checkbox {
  padding-left: 30px;
  height: 17px;
}

input[type=text].group-from-checkbox-input {
  width: 250px;
}

.region-fare-container .general-container [type="radio"]:not(:checked) + label, .region-fare-container .general-container [type="radio"]:checked + label {
  height: auto;
}

.region-fare-container .general-container .layout {
  margin-top: -11px;
}

.region-fare-container .general-container .layout .overlay {
  opacity: 0;
}

.region-fare-container .general-container .layout .overlay:hover {
  opacity: 0;
}

.region-fare-container .general-container input:not([type]), .region-fare-container .general-container input[type=text], .region-fare-container .general-container input[type=password], .region-fare-container .general-container input[type=email], .region-fare-container .general-container input[type=url], .region-fare-container .general-container input[type=time], .region-fare-container .general-container input[type=date], .region-fare-container .general-container input[type=datetime], .region-fare-container .general-container input[type=datetime-local], .region-fare-container .general-container input[type=tel], .region-fare-container .general-container input[type=number], .region-fare-container .general-container input[type=search], .region-fare-container .general-container textarea, .region-fare-container .general-container textarea.materialize-textarea {
  background: transparent;
}

.destination [type="radio"] + label:before, .destination [type="radio"] + label:after {
  margin: 4px 0;
}

input[type=text].fares-vdn {
  width: 300px;
  margin-left: 15px;
  margin-right: 15px;
}

.fare-date {
  line-height: 56px;
  margin: 0;
  font-weight: bold;
  color: #e91e63;
}

.main-container table.table-approval-status a {
  position: relative;
  color: #3189DD;
}

.main-container table.table-approval-status a:after {
  content: '';
  position: absolute;
  bottom: -5px;
  width: 0%;
  left: 50%;
  height: 3px;
  background-color: #3189DD;
  -webkit-transition: 300ms all ease-out;
  -moz-transition: 300ms all ease-out;
  -ms-transition: 300ms all ease-out;
  -o-transition: 300ms all ease-out;
  transition: 300ms all ease-out;
}

.main-container table.table-approval-status a:hover:after {
  width: 100%;
  left: 0%;
}

.main-container table.table-approval-status a.btn:after, .main-container table.table-approval-status a.brand-logos:after {
  display: none;
}

.main-container table.table-approval-status a.btn {
  color: #fff;
}

.main-container table.table-approval-status a.cr-inherit {
  color: inherit;
}

.ind-approval-container .button-container {
  margin: 25px 0 35px;
}

.ind-approval-container .button-container a.btn {
  padding-left: 15px;
  height: auto;
  text-align: center;
  padding-right: 15px;
}

.ind-edm .header-title, .ind-edm .header-status {
  padding: 10px 15px;
  margin: 0;
  color: #fff;
}

.ind-edm .header-title.header-title, .ind-edm .header-status.header-title {
  background-color: #244298;
}

.ind-edm p.header-status {
  float: left;
  background: none;
  color: #000;
  font-weight: bold;
  padding: 10px 15px;
}

.ind-edm p.header-status.mt1 {
  margin-top: 10px;
}

.ind-edm table p {
  margin: 8px 0;
}

.ind-edm table .thumbnail {
  max-width: 200px;
}

.ind-edm table .btn {
  height: auto;
  text-transform: initial;
}

.ind-edm table tr:nth-child(odd) {
  background-color: #fff;
}

.ind-edm table tr:nth-child(even) {
  background-color: #eee;
}

.ind-edm table tr:nth-child(1) {
  color: #fff;
}

.ind-edm table tr th {
  background-color: #244298;
  border-radius: 0px;
  position: relative;
}

.ind-edm table tr th:first-child {
  width: 9%;
}

.ind-edm table tr th:nth-child(2), .ind-edm table tr th:nth-child(3), .ind-edm table tr th:nth-child(4) {
  width: 30%;
  position: relative;
}

.ind-edm table tr th:nth-child(2):after, .ind-edm table tr th:nth-child(3):after, .ind-edm table tr th:nth-child(4):after {
  content: '';
  position: absolute;
  left: -1px;
  width: 1px;
  height: 20px;
  background-color: #fff;
  top: 11px;
}

.edm-individual.ind-edm .btn {
  font-weight: 400;
}

.edm-individual.ind-edm table tr th {
  background: none;
}

.edm-individual.ind-edm table.add-new-table tr td:first-child {
  width: 378px;
}

.edm-individual.ind-edm tr.pending td, .edm-individual.ind-edm tr.pass td {
  padding: 15px;
}

.edm-individual.ind-edm tr.pending {
  background-color: #fde1bc;
}

.edm-individual.ind-edm tr.pending .header-status {
  border: 5px solid #ff8f00;
}

.edm-individual.ind-edm tr.pass {
  background-color: #ccfbc3;
}

.edm-individual.ind-edm tr.pass .header-status {
  border: 5px solid #61c165;
}

.email-title {
  margin: 0;
  line-height: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  font-weight: bold;
  color: #e91e63;
}

.email-seding-date input {
  text-indent: 0;
  width: 100px;
  font-size: 12px;
  border-bottom: 0;
  border: 0;
  background: transparent;
}

.button_edit_sending {
  top: 20px;
  left: 0;
  position: absolute;
  color: blue;
}

.file-field .btn {
  height: 2.5rem;
  line-height: 2.5rem;
}

.file-path-wrapper input[type=text] {
  height: 2.5rem;
  line-height: 2.5rem;
}

.btn-outer-container {
  padding: 10px 15px;
}

.btn-outer-container .btn {
  margin: 0 5px;
}

.btn-outer-container .btn:first-child {
  margin-left: 0;
}

table.banner-settings tr th, table.banner-settings tr td {
  text-align: center;
}

table.banner-settings tr th:first-child, table.banner-settings tr th:last-child, table.banner-settings tr td:first-child, table.banner-settings tr td:last-child {
  text-align: left;
}

.banner-add-container .add-new-table .bg-img-container .file-path-wrapper input.file-path {
  background-color: transparent;
}

.banner-add-container h4 {
  padding: 20px 30px;
  color: #000;
  background-color: #fff;
  font-weight: bold;
}

.banner-add-container table th, .banner-add-container table td {
  padding: 10px 30px;
}
