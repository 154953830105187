textarea.materialize-textarea{
  text-indent:0px;
  padding:10px 20px;
  min-height:100px;
  max-width:calc( 100% - 50px );
  box-sizing:content-box;
  background:#fff;
}
.add-new-table{
  @extend .par-large;
  .bg-img-label{
    padding:20px 0;
  }
  label{
    color:#000;
  }
  input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea ,textarea.materialize-textarea{
    background-color:#fff;
    border:1px solid #333;
    text-indent:20px;
    margin:10px 0;
    &:focus:not([readonly]) {
      border-bottom: 1px solid #333;
      box-shadow: 0 0 0 0 #333;
    }
  }
  textarea.materialize-textarea{
    text-indent:0px;
    padding:10px 20px;
    min-height:100px;
    max-width:calc( 100% - 50px );
    box-sizing:content-box;
  }
  .datepicker-container{
    max-width:100%;
    width:180px;
    position:relative;
    &:after{
      content:'\f073';
      font-family:"FontAwesome";
      position:absolute;
      right:10px;
      top:17px;
      font-size:2em;
      cursor:pointer;
      z-index:1;
    }
    input.datepicker{
      position:relative;
      z-index:2;
      background-color:transparent;
      cursor:pointer;
    }
  }
  [type="radio"]:not(:checked) + label, [type="radio"]:checked + label ,[type="radio"] + label{
    padding-left:25px;
    padding-right:20px;
    font-size:1em;
  }
  table.picker__table{
    tr:nth-child(odd),tr:nth-child(even){
      background-color:#fff;
      td{
        padding:0;
      }
    }
  }
  .bg-img-container{
    margin-top:0;
    input{
      margin:0;
    }
    .file-path{
      border:none;
      text-indent:0px;
      border-bottom:1px solid;
    }
  }
  .dragging, .dragging * {
    cursor: move !important;
  }

  .dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
  }
  .selector-list{
    min-width:250px;
    p{
      text-align:center;
      font-weight:700;
      margin:10px 0;
    }
  }
  .buttons-container{
    text-align:center;
    margin: 50px;
    button{
      margin:5px;
    }
  }
  .custom-selector-list .ui-selecting {
    background-color: #4ED0F6 !important;
    color:#fff;
  }
  .custom-selector-list .ui-selected {
    background-color: #4ED0F6 !important;
    color:#fff;
  }
  .custom-selector-list {
    border:1px solid #000;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background:white;
    min-height:300px;
  }
  .custom-selector-list li {
    margin: 3px;
    padding: 10px 15px;
    height: 18px;
    box-sizing:content-box;
    cursor:pointer;
  }
  .custom-selector-list li:hover {
    @include transition (all 300ms ease-out);
    background-color:#eee;
  }
}
.fixed-table {
  table-layout: fixed;
  td {
    word-wrap: break-word;
  }
}
.popup{
  @extend .par-large;
  &.delete,
  &.delete-destination,
  &.clone,
  &.download,
  &.edit-edm{
    width:300px;
    max-width:100%;
    max-height:100%;
    padding:20px 0;
    top:calc( 50% - 180px ) !important;
    text-align:center;
    .modal-footer{
      text-align:center;
      a{
        float:none;
      }
    }
  }
  label{
    color:#000;
  }
  .header-title{
    background-color:rgba(37,69,131,1);
    color:#fff;
    padding:10px 15px;
  }
  [type="radio"]:not(:checked) + label, [type="radio"]:checked + label{
    padding-left:30px;
  }
  .bg-img-container{
    margin-top:0;
    input{
      margin:0;
    }
    .file-path{
      border:none;
      text-indent:0px;
      border-bottom:1px solid;
    }
  }
  .half{
    float:left;
    width:calc( 50% - 30px );
    padding: 0 15px;
    .img-container{
      width:100%;
      background-color:#ddd;
      min-height:50px;
      img{
        max-width:100%;
      }
    }
    .half{
      width:50%;
      padding:0 5px;
    }
  }
  &.modal.banner .modal-footer .btn{
    margin: 8px 5px;
  }
  &.banner{
    table{
      tr{
        padding:10px 0;
      }
      tr:nth-child(odd){

      }
    }
  }
}
.submit-container{
  margin:20px 15px;
}
.submit-button{
  background-color:#244298 !important;
  &.is-sb-green {
    background-color:#4caf50 !important;
  }
}
.content-management{
  .btn{
    font-weight:900;
    cursor:pointer;
    span{
      cursor:pointer;
    }
  }
}
.fares-input{
  width:40% !important;
  margin:0 10px !important;
}
